<template>
  <div class="bzdetail">
    <div v-if="!error" class="content">
      <div class="main">
        <div class="pic_main"><img :src="bz_data.pic_url" alt="" /></div>
        <div class="introduction">
          <div class="download">
            <a
              class="down_btn"
              :class="{ enabled: login_status, disabled: !login_status }"
              :disabled="!login_status"
              href=""
              @click.prevent="down_load"
              ><span>下载高清图片(每次消耗10积分)</span></a
            >
            <a
              class="down_btn column_margin"
              :class="{ enabled: login_status, disabled: !login_status }"
              :disabled="!login_status"
              href=""
              @click.prevent="down_load_normal"
              ><span>下载普通图片(免费)</span></a
            >
            <div v-if="!login_status" class="column_margin">
              <span>未登录时无法使用下载功能，马上</span
              ><a class="login_link" @click="login_btn_click_event"
                >登录/注册</a
              >
              <Login v-if="!login_status" ref="login"></Login>
            </div>
          </div>
          <div class="base_mse"><span>基本信息: </span></div>

          <ul>
            <li special>
              <span class="msg_title">图片分辨率(宽*长) : </span
              ><span class="msg_detail"
                >{{ bz_data.width }} * {{ bz_data.height }}</span
              >
            </li>
            <li>
              <span class="msg_title">上传时间 : </span
              ><span class="msg_detail">{{ bz_data.upload_date }}</span>
            </li>
            <li>
              <span class="msg_title">浏览量 : </span
              ><span class="msg_detail">{{ bz_data.visit_cnt }}</span>
            </li>
            <li>
              <span class="msg_title">下载量 : </span
              ><span class="msg_detail">{{ bz_data.download_cnt }}</span>
            </li>
            <li>
              <span class="msg_title">浏览用户数 : </span
              ><span class="msg_detail">{{ bz_data.visit_user_cnt }}</span>
            </li>
            <li>
              <span class="msg_title">下载用户数 : </span
              ><span class="msg_detail">{{ bz_data.download_user_cnt }}</span>
            </li>
          </ul>

          <!-- <div class="describe">
            <span>声明: </span>
            <p></p>
          </div> -->
        </div>
      </div>

      <!-- <div class="divite">
        <span>----------------------------</span>
        有想说的话吗，欢迎评论区留言<span>----------------------------</span>
      </div>

      <div class="showComm">
        <div
          class="comments"
          v-for="(ele, index) in cur_circle_data.children"
          :key="index"
        >
          <div class="test">
            <div class="comment-item">
              <img class="commener_img" :src="ele.avatar_url" alt="" />
              <div class="comment-content">
                <div class="text">
                  <a class="commener">{{ ele.nick_name }}&nbsp;:&nbsp;</a>
                  {{ ele.content }}
                </div>
                <div class="comment-interactive">
                  <div class="left">
                    <span class="time">{{ ele.publish_time }}</span>
                  </div>
                  <div class="right">
                    <a
                      class="rate"
                      @click="click_reply_btn($event, { cur: ele }, index)"
                      ><i class="iconfont icon-pinglun1"></i>
                    </a>
                    <a
                      class="dianzan iconfont"
                      @click="like_btn_click($event, ele, 4)"
                      ><i class="iconfont" :class="like_btn_style(ele)"> </i>
                      &nbsp;<span v-if="ele.like_cnt > 0">{{
                        ele.like_cnt
                      }}</span></a
                    >
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="'children' in ele && ele.children.length > 0"
              class="comment-child"
              v-for="(elem, index2) in ele.children"
            >
             
              <img class="commener_img" :src="elem.avatar_url" alt="" />
              <div class="comment-content">
                <div class="text">
                  <a class="commener"
                    >{{ elem.nick_name }}
                    <span v-if="elem.is_other_reply">
                      &nbsp;&nbsp;<span class="statis-text">{{
                        "回复" + ""
                      }}</span>
                      &nbsp;&nbsp;{{ elem.parent_nick_name }}</span
                    >&nbsp;:&nbsp;</a
                  >
                  {{ elem.content }}
                </div>
                <div class="comment-interactive">
                  <div class="left">
                    <span class="time">{{ elem.publish_time }}</span>
                  </div>
                  <div class="right">
                    <a
                      class="rate"
                      @click="
                        click_reply_btn($event, { cur: elem, par: ele }, index)
                      "
                      ><i class="iconfont icon-pinglun1"></i>
                    </a>
                    <a
                      class="dianzan iconfont"
                      @click="like_btn_click($event, elem, 4)"
                      ><i class="iconfont" :class="like_btn_style(elem)"> </i>
                      &nbsp;<span v-if="elem.like_cnt > 0">{{
                        elem.like_cnt
                      }}</span></a
                    >
                  </div>
                </div>
              </div>
            </div>
            <a
              href=""
              v-if="ele.child_comment_cnt > 0"
              class="more-reply"
              @click="view_replay($event, ele)"
              ><span>还有{{ ele.child_comment_cnt }}条回复</span></a
            >
          </div>
        </div>
      </div>
      <div class="comment">
        <SingleComment :target_id="target_id"></SingleComment>
      </div> -->
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
  </div>
</template>

<script type="text/babel">
import Vue from "vue";
import VueRouter from "vue-router";
import Login from "./Login.vue";
import { generate_unique_flag, get_token,isLoggedIn } from "../common/common.js";
// import { getBucketLocation } from "ali-oss/lib/bucket";
// import SingleComment from "./SingleComment.vue";
// import DividerLineWithTitle from "./DividerLineWithTitle.vue";

Vue.use(VueRouter);
export default {
  data() {
    return {
      bz_data: {},
      bz_id: this.$route.params.bz_id,
      type: this.$route.params.type,
      is_login: false,
      is_click_login_btn: false,
      error: false,
      // target_id: bz_data.pic_id,
    };
  },
  props: ["param1", "param2"],
  methods: {
    login_btn_click_event() {
      this.$nextTick(() => {
        this.$refs.login.click_login_btn();
      });
    },
    async get_bz_detail() {
      console.log("图片详情: ", { bz_id: this.bz_id, type: this.type });
      try {
        var response = await this.$http.post(
          "http://www.soutudashi.com/get_bz_detail",
          {
            headers: { "Content-Type": "application/json" },
            data: { bz_id: this.bz_id, type: this.type },
          }
        );
        return response.data;
      } catch (e) {
        // console.log(e);
      }
    },

    // 获取当前积分
    async get_integral() {
      let integral_num = {};
      try {
        const token = get_token("token");
        // console.log("获取积分");
        integral_num = await this.$http({
          method: "post",
          url: "http://www.soutudashi.com/get_integral", // 拼接URL和参数
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", // 设置跨域参数
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${token}`,
          },
          responseType: "json",
        });
        // console.log("Dsdgsdgsdgd: ", integral_num);
        if (integral_num.status == 200) {
          // console.log("~~~~~~~~~~~,", integral_num);
          // return JSON.parse(integral_num.data).data.num;
          return integral_num.data.data.num;
        } else {
          return 0;
        }
      } catch (e) {
      } finally {
      }
    },

    // 下载图片
    async down_load(event) {
      if (!this.login_status) {
        event.preventDefault();
      } else {
        //  下载图片前先判断积分是否充足，不充足就弹提示，结束下面的流程
        const integral_num = await this.get_integral();
        // console.log("当前积分: ", integral_num);
        if (integral_num < 10) {
          this.$message({
            message: "当前账户不足10积分，请在用户-积分充值栏进行充值后在尝试",
            type: "error",
          });
          return;
        }
        let img_url = this.bz_data.pic_url;

        try {
          // console.log("进入请求图片请求!!!!!", img_url);
          const token = get_token("token");
          // console.log("进入图片区i给你求: ", token);

          const verify_res = await this.$http({
            method: "get",
            url: "http://www.soutudashi.com/verify_account", // 拼接URL和参数
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*", // 设置跨域参数
              "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
              Authorization: `Bearer ${token}`,
            },
            responseType: "json",
          });

          if (verify_res.data.status != 200) {
            // console.log("发送消息,", verify_res.data.message);
            this.$message({ message: verify_res.data.message, type: "error" });
            return;
          }
          this.$http({
            method: "get",
            url: `http://www.soutudashi.com/download_image?url=${img_url}`, // 拼接URL和参数
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*", // 设置跨域参数
              "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
              Authorization: `Bearer ${token}`,
            },
            responseType: "arraybuffer",
          })
            .then((blob) => {
              // console.log("下载图片blob!!!!!!!!!!!!!!", blob);

              if (blob.data.byteLength >= 1024) {
                const res = blob.config.url;
                let file_name = "";
                const res_length = res.length;
                if (res_length > 0) {
                  // const url = res[0];
                  const index1 = res.lastIndexOf("/");
                  const index2 = res.lastIndexOf("%2F");
                  let index_res;
                  if (index1 > index2) {
                    index_res = index1;
                  } else {
                    index_res = index2;
                  }
                  if (res_length > index_res) {
                    file_name = res.substring(index_res + 1);
                    // let middle_file_name = res.substring(index_res + 1);
                    // const index3 = middle_file_name.lastIndexOf(".");

                    // file_name = middle_file_name.substring(0, index3) + ".webp";
                  } else {
                    file_name = "not_found.png";
                  }
                }

                const url = URL.createObjectURL(new Blob([blob.data]));
                // const url = URL.createObjectURL(blob.data);

                // console.log("下载图片的url: ", url, file_name);
                const a = document.createElement("a");

                // a.style.display = "none";
                a.target = "_blank";
                a.class = "test";
                a.href = url;
                a.download = file_name;
                // a.setAttribute("target", "_blank");
                // a.setAttribute("href", url);
                document.body.appendChild(a);

                // window.open(url, "_blank");

                a.click();

                document.body.removeChild(a);
                URL.revokeObjectURL(url);

                this.statis_download_bz_data();
                // console.log("最终结果: ", blob, blob.data);

                const tokens = get_token("token");
                // console.log("计算token: ", tokens);
                this.sub_points(tokens, 10);
                // this.$message({
                //   message: "图片下载成功",
                //   type: "success",
                // });
              } else {
                this.$message({ message: "图片下载失败", type: "fail" });
              }
            })
            .catch((error) => {
              this.$message({
                message: "图片下载失败",
                type: "fail",
              });
              // console.log("下载图片错误： ", error);
            });
        } catch (e) {
          console.log("error: ", e);
        }
      }
    },

    async sub_points(token, points) {
      try {
        // const data = { point: points };
        // console.log("sub_points参数: ", token, points);
        var response = await this.$http({
          method: "post",
          url: "http://www.soutudashi.com/deducte_points", // 拼接URL和参数
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", // 设置跨域参数
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${token}`,
          },
          data: {
            point: points,
          },
          responseType: "json",
        });

        // var response = await this.$http.post(
        //   "http://www.soutudashi.com/deducte_points",
        //   {
        //     headers: {
        //       "Content-Type": "application/json",
        //       "Access-Control-Allow-Origin": "*", // 设置跨域参数
        //       "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",

        //       Authorization: `Bearer ${token}`,
        //     },
        //     data,
        //   }
        // );
        this.$message({
          message: "扣除10积分",
          type: "success",
          customClass: "messageIndex",
        });
        // console.log("扣除积分成功");
        // console.log("data:", response, response.data);
        return response.data;
      } catch (e) {
        // console.log("扣除积分失败: ", e);
      } finally {
      }
    },
    async down_load_normal(event) {
      if (!this.login_status) {
        event.preventDefault();
      } else {
        let img_url = this.bz_data.pic_url;
        const lastDotIndex = img_url.lastIndexOf(".");
        if (lastDotIndex === -1) {
          // 如果没有找到点，返回null
          return null;
        }

        const afterLastDot = img_url.substring(lastDotIndex + 1);
        const beforeLastDot = img_url.substring(0, lastDotIndex);
        const final_url = beforeLastDot + ".webp";

        // console.log("普通下载: ", final_url);
        try {
          const token = get_token("token");
          this.$http({
            method: "get",
            url: `http://www.soutudashi.com/download_image?url=${final_url}`, // 拼接URL和参数
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*", // 设置跨域参数
              "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
              Authorization: `Bearer ${token}`,
            },
            responseType: "arraybuffer",
          })
            // .then((response) => response.blob())
            .then((blob) => {
              const res = blob.config.url;
              let file_name = "";
              const res_length = res.length;
              if (res_length > 0) {
                // const url = res[0];
                const index1 = res.lastIndexOf("/");
                const index2 = res.lastIndexOf("%2F");
                let index_res;
                if (index1 > index2) {
                  index_res = index1;
                } else {
                  index_res = index2;
                }
                if (res_length > index_res) {
                  file_name = res.substring(index_res + 1);
                } else {
                  file_name = "not_found.webp";
                }
              }
              const url = URL.createObjectURL(new Blob([blob.data]));
              // console.log("blob: ", blob);
              const a = document.createElement("a");
              // a.class = "test";
              // a.href = url;
              // a.download = file_name;
              // URL.revokeObjectURL(url);
              a.target = "_blank";
              a.class = "test";
              a.href = url;
              a.download = file_name;
              document.body.appendChild(a);

              // window.open(url, "_blank");

              a.click();

              document.body.removeChild(a);
              URL.revokeObjectURL(url);
              this.statis_download_bz_data();

              this.$message({
                message: "图片下载成功",
                type: "success",
              });
            })
            .catch((error) =>
              this.$message({
                message: "下载图片失败",
                type: "error",
              })
            );
        } catch (e) {}
      }
    },
    async statis_visit_bz_data() {
      try {
        var response = await this.$http.post(
          "http://www.soutudashi.com/modify_bz_visit",
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*", // 设置跨域参数
              "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            },
            data: { bz_id: this.bz_id, type: this.type },
          }
        );

        // console.log("数据统计值: ", response, response.data);
        return response.data;
      } catch (e) {
        // console.log(e);
      }
    },

    async statis_download_bz_data() {
      try {
        var response = await this.$http.post(
          "http://www.soutudashi.com/modify_bz_download",
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*", // 设置跨域参数
              "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            },
            data: { bz_id: this.bz_id, type: this.type },
          }
        );

        // console.log("数据统计值: ", response, response.data);
        return response.data;
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    await this.statis_visit_bz_data();
    this.bz_data = await this.get_bz_detail();
    if (this.bz_data.pic_url == null) {
      this.error = false;
    }
  },

  created() {
    generate_unique_flag();
    // console.log("环境变量信息:", process.env);
  },

  computed: {
    login_status() {
      return isLoggedIn();
    },

    target_id() {
      // console.log("发送模板id： ", this.bz_data.pic_id);
      return this.bz_data.pic_id;
    },
  },
  components: {
    Login,
    // SingleComment,
    // DividerLineWithTitle,
  },
};
</script>
<style scoped>
.bzdetail {
  width: 100%;
  height: 100%;
  position: relative;
}
.content {
  /* width: 800px;
  height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 100px;
  flex-direction: column;
}

.content > .main {
  display: flex;
  flex-direction: row;
  /* width: 600px; */
  /* background: rgb(196, 229, 105); */
}

.content > .comment {
  width: calc(50%);
}
.main > .pic_main {
  flex: 1.8;
}

.main > .introduction {
  margin-left: 20px;
  padding: 10px;
  /* flex: 0.8; */
}

.base_mse {
  text-align: left;
}
img {
  /* width: 350px; */
  height: 700px;
}

.msg_title {
  height: 40px;
  display: inline-block;
  line-height: 40px;
  /* color: white; */
}

.msg_detail {
  height: 36px;
  line-height: 30px;
  display: inline-block;
  /* color: white; */
}

.special {
  border-right: 5px solid white;
}
li {
  text-decoration: none;
  list-style-type: none;
  background-color: rgb(242, 242, 242);
  /* margin-bottom: 5px; */
  /* float: left; */
  /* width: 33%; */
  /* border-right: 5px solid white; */
  box-sizing: border-box;
  /* text-align: left; */
  /* display: flex; */
  align-items: left;
}

.introduction > ul {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  /* align-items: center; */
  flex-wrap: wrap;
  margin-top: 5px;
  /* padding: 10px; */
  background-color: rgb(242, 242, 242);
}

.disabled {
  background-color: rgb(200, 200, 200, 0.5);
}

.enabled {
  background-color: rgb(196, 229, 105);
}
.down_btn {
  width: 100%;
  height: 30px;
  /* background: rgb(196, 229, 105); */
  display: block;
  text-decoration: none;
  text-align: center;
  line-height: 30px;
  /* color: white; */
}

.login_link {
  width: 200px;
  height: 100px;
  background-color: aqua;
}

.column_margin {
  margin: 20px 0px;
}
.messageIndex {
  z-index: 999999 !important;
}
</style>
